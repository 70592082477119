<template>
  <div class="products">
    <div class="page-header">
      <h1>{{ $t('Order & Document Management')}}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Documents') }}</h2>
        <router-link :to="{ name: 'CreateDocument' }">
          <el-button class="action-button" type="primary">{{ $t('Add Document') }}</el-button>
        </router-link>
      </div>

      <ItemFilter :placeholder="$t('Search by document no.')" default="documentNum" :selection="false" @search="searchItems"></ItemFilter>

      <div class="invoice-list">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">{{ $t('Document no.') }}</th>
              <th scope="col">{{ $t('Related Order ID') }}</th>
              <th scope="col">{{ $t('Created Date') }}</th>
              <th scope="col">{{ $t('Actions') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="document in documents">
              <td>{{ document.reference_id }}</td>
              <td>{{ $t(document.document_type) }}</td>
              <td>
                <router-link :to="{name:'Order', params: {id: document.related_order}}" v-if="document.related_order_info">
                  {{ document.related_order_info.reference_id }}
                </router-link>
              </td>
              <td>{{ formatDateTime(document.created_date, 'YYYY-MM-DD HH:mm:ss') }}</td>
              <td>
                <div class="action-links">
                  <a class="action-link" target="_blank" :href="`${documentUrl}${document.id}?token=${loginToken}`">{{ $t('View') }}</a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ItemFilter from '@/components/Filter.vue';
import Common from '@/lib/common';
import Document from '@/lib/document';

export default {
  name: 'Documents',
  components: {
    ItemFilter,
  },
  mounted(){
    const loginInfo = Common.getLoginInfo();
    const token = loginInfo['token'];
    this.loginToken = token;
    this.documentUrl = `${this.apiUrl}/document/`;
    this.loadAllDocuments();
  },
  methods:{
    formatDateTime(timestamp, format){
      return Common.formatDateTime(timestamp, format);
    },
    async onTabChange(){
      const activeName = this.activeName;
      if(activeName !== 'All'){
        this.loadLimitDocuments(activeName);
      }else{
        this.documents = this.orignalDocuments;
      }
    },
    searchItems(form){
      const searchField = {};
      let newItems = [];
      switch(form.filterTarget){
        case 'documentNum':
        searchField['reference_id'] = form.filterValue;
        newItems = Common.filterItems(searchField, this.orignalDocuments);
        this.searchDocuments = newItems;
        this.documents = newItems;
        break;
      }
    },
    async loadLimitDocuments(type){
      const searchFields = {
        'document_type': '',
      }
      if(type === 'Invoice'){
        if(this.searchDocuments){
          const newItems = this.searchDocuments.filter(function(document){
            return document['document_type'] == 'Invoice';
          });
          this.documents = newItems;
        }else{
          const newItems = this.orignalDocuments.filter(function(document){
            return document['document_type'] == 'Invoice';
          });
          this.documents = newItems;
        }
      }
      if(type === 'Quotation'){
        if(this.searchDocuments){
          const newItems = this.searchDocuments.filter(function(document){
            return document['document_type'] == 'Quotation';
          });
          this.documents = newItems;
        }else{
          const newItems = this.orignalDocuments.filter(function(document){
            return document['document_type'] == 'Quotation';
          });
          this.documents = newItems;
        }
      }
    },
    async loadAllDocuments(){
      try{
        const loginInfo = Common.getLoginInfo();
        const documents = await Document.loadAllDocuments(this.apiUrl, loginInfo);
        this.orignalDocuments = documents;
        this.documents = documents;
      }catch(err){
        console.log(err);
      }
    },
  },
  data(){
    return {
      loginToken: '',
      documentUrl: '',
      activeName: 'All',
      documents: [],
      searchDocuments: null, //Keep the tab result
      orignalDocuments: [],
    }
  },
  computed: {
    ...mapState({
     apiUrl: state => state.apiUrl,
   }),
 },
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
</style>
